import styled from "styled-components/macro";

const StyledContainer = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;

  overflow: auto;
`;

export { StyledContainer };
