/* tslint:disable */
/* eslint-disable */
/**
 * SSBackend
 * Backend for Sound Station
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface PaginatedGetPlaylistsResponse
 */
export interface PaginatedGetPlaylistsResponse {
    /**
     * 
     * @type {Array<Playlist>}
     * @memberof PaginatedGetPlaylistsResponse
     */
    'items': Array<Playlist>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedGetPlaylistsResponse
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedGetPlaylistsResponse
     */
    'next'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedGetPlaylistsResponse
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedGetPlaylistsResponse
     */
    'previous'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedGetPlaylistsResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface Playlist
 */
export interface Playlist {
    /**
     * 
     * @type {boolean}
     * @memberof Playlist
     */
    'collaborative': boolean;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'external_url': string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'href': string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'primary_color'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Playlist
     */
    'public': boolean;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'snapshot_id': string;
    /**
     * 
     * @type {number}
     * @memberof Playlist
     */
    'tracks'?: number;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Playlist
     */
    'uri': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<any> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpotifyApi - axios parameter creator
 * @export
 */
export const SpotifyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Connect to Spotify
         * @summary Connect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spotify/connect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get playlists
         * @summary Playlists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylists: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spotify/playlists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotifyApi - functional programming interface
 * @export
 */
export const SpotifyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotifyApiAxiosParamCreator(configuration)
    return {
        /**
         * Connect to Spotify
         * @summary Connect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get playlists
         * @summary Playlists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaylists(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedGetPlaylistsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaylists(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpotifyApi - factory interface
 * @export
 */
export const SpotifyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotifyApiFp(configuration)
    return {
        /**
         * Connect to Spotify
         * @summary Connect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connect(options?: any): AxiosPromise<any> {
            return localVarFp.connect(options).then((request) => request(axios, basePath));
        },
        /**
         * Get playlists
         * @summary Playlists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaylists(options?: any): AxiosPromise<PaginatedGetPlaylistsResponse> {
            return localVarFp.getPlaylists(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpotifyApi - object-oriented interface
 * @export
 * @class SpotifyApi
 * @extends {BaseAPI}
 */
export class SpotifyApi extends BaseAPI {
    /**
     * Connect to Spotify
     * @summary Connect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotifyApi
     */
    public connect(options?: AxiosRequestConfig) {
        return SpotifyApiFp(this.configuration).connect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get playlists
     * @summary Playlists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotifyApi
     */
    public getPlaylists(options?: AxiosRequestConfig) {
        return SpotifyApiFp(this.configuration).getPlaylists(options).then((request) => request(this.axios, this.basePath));
    }
}


