import React, { FC } from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { ReactComponent as SpotifySvg } from "src/assets/spotify.svg";

const SpotifyIcon: FC = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SpotifySvg} {...props} />
);

export { SpotifyIcon };
