import { Configuration, SpotifyApi } from "./generated";

const getSpotifyApi = (): SpotifyApi => {
  return new SpotifyApi(
    new Configuration({
      basePath: "http://localhost:8000",
    })
  );
};

export { getSpotifyApi };
