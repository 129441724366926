import React, { FC } from "react";
import { Layout, Menu, MenuProps } from "antd";
import styled from "styled-components/macro";
import { SpotifyIcon } from "src/components/icons";
import { Link, Route, Routes } from "react-router-dom";

import Playlists from "../playlists";
import Albums from "../albums";
import Tracks from "../tracks";
import Syncs from "../syncs";
import History from "../history";

const StyledLayout = styled(Layout)`
  height: 100vh;
  width: 100vw;
`;

const StyledLayoutContent = styled(Layout.Content)`
  display: flex;
  flex-direction: row;

  & > :last-child {
    flex: 1;
  }
`;

const StyledSideNav = styled(Menu)<{ width: number; $background?: string }>`
  width: ${(props) => props.width}px;
  height: 100%;
  background: ${(props) => props.$background || "unset"};
`;

type MenuItem = Required<MenuProps>["items"][number];

const Dashboard: FC = () => {
  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: Array<MenuItem>
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const musicItems: Array<MenuItem> = [
    getItem("Spotify", "spotify", <SpotifyIcon />),
    getItem("Apple Music", "apple-music", <SpotifyIcon />),
    getItem("YT Music", "yt-music", <SpotifyIcon />),
    getItem("SoundCloud", "soundcloud", <SpotifyIcon />),
    getItem("Tidal", "tidal", <SpotifyIcon />),
  ];

  const navItems: Array<MenuItem> = [
    getItem(<Link to={"/"}>Playlists</Link>, "/"),
    getItem(<Link to={"/albums"}>Albums</Link>, "/albums"),
    getItem(<Link to={"/tracks"}>Tracks</Link>, "/tracks"),
    getItem(<Link to={"/syncs"}>Syncs</Link>, "/syncs"),
    getItem(<Link to={"/history"}>History</Link>, "/history"),
  ];

  return (
    <StyledLayout>
      <StyledLayoutContent>
        <StyledSideNav
          width={200}
          mode={"vertical"}
          theme={"dark"}
          items={musicItems}
          selectedKeys={[]}
        />
        <StyledSideNav
          width={250}
          selectedKeys={[location.pathname]}
          mode={"vertical"}
          theme={"dark"}
          items={navItems}
          $background={"#090909"}
        />

        <div>
          <Routes>
            <Route path={"*"} element={<Playlists />} />
            <Route path={"/albums"} element={<Albums />} />
            <Route path={"/tracks"} element={<Tracks />} />
            <Route path={"/syncs"} element={<Syncs />} />
            <Route path={"/history"} element={<History />} />
          </Routes>
        </div>
      </StyledLayoutContent>
    </StyledLayout>
  );
};

export default Dashboard;
