import React, { FC } from "react";
import { StyledContainer } from "src/components/common";
import { useQuery } from "react-query";
import { Table, Typography } from "antd";
import { getSpotifyApi } from "src/api";
import spotifySvg from "src/assets/spotify.svg";

const Playlists: FC = () => {
  const { data: playlists, isLoading } = useQuery("playlists", async () => {
    const api = getSpotifyApi();
    const response = await api.getPlaylists();

    return response.data;
  });

  const columns = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      render: (image: string) => (
        <img width={40} src={image || spotifySvg} alt={""} />
      ),
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Owner", dataIndex: "owner", key: "owner" },
    { title: "Tracks", dataIndex: "tracks", key: "tracks" },
  ];

  return (
    <StyledContainer>
      <Typography.Title> Playlists </Typography.Title>
      <Table
        loading={isLoading}
        pagination={false}
        dataSource={playlists?.items}
        columns={columns}
      />
    </StyledContainer>
  );
};

export default Playlists;
